'use strict';

var accordion = function () {
    var $container = $(this).closest('.accordion');
    var $content = $container.find('.js-accordion-body');
    
    $container.toggleClass('m-opened');
    $content.slideToggle(250);
};

module.exports = function () {
    $('.js-accordion-switcher').on('click', accordion);
};
