'use strict';

var util = require('../../util');
var shipping = require('./shipping');

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
    var $form = $('.address');
    // select address from list
    $('select[name$="_addressList"]', $form).on('change', function () {
        $('.checkout-billing input[name$="_useShippingAsBilling"]').prop('checked', false);
        // Briefly hide the payment section to avoid premature form validation on the payment section
        $(".checkout-billing-paymentmethods").hide();
    	
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, $form);
        shipping.updateShippingMethodList();
        // re-validate the form
        $form.validate().form();
        
        $(".checkout-billing-paymentmethods").show();
    });

    $('[name$="_address1"], [name$="_address2"]').on('keypress keydown keyup change paste', function(e) {
    	var max = 40;
    	if (this.value.length == max && e.keyCode !== 46 && e.keyCode !== 8 && e.keyCode !== 9 && !util.getSelectionText().length) {
            e.preventDefault();
        } else if (this.value.length > max) {
            this.value = this.value.substring(0, max);
        }
    	
    	if(this.value.length == max){
    		$(this).closest('.form-row').find('.form-caption').show().text('40 characters limit');
    	} else {
    		$(this).closest('.form-row').find('.form-caption').hide();
    	}
    });
};
