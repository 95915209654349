'use strict';

var stickyComponent = require('./components/stickyComponent');

function getPageMapping() {
    var obj = SitePreferences.MAPPING_FOR_STICKY_COMP;
    if (!obj.length) {
        return {
            home: '.pt_storefront',
            plp: '.pt_product-search-result',
            pdp: '.pt_product-details',
            search: '.pt_product-search-noresult'
        }
    }
    return JSON.parse(obj);
}

var stickySearch = {
    init: function () {
        var pageString = SitePreferences.STICKY_SEARCH_BAR_LIST.toLowerCase();
        if(!pageString.length) {
            return;
        }
        var pageList = pageString.split(',');

        var pageMapping = getPageMapping();

        var isEnabled = pageList.some(item => pageMapping.hasOwnProperty(item.trim()) && $(pageMapping[item.trim()]).length);

        if (isEnabled) {
            stickyComponent.init({
                selector: '.header-search',
                trigger: '.top-banner'
            });

            if ($('body').find('.pt_product-details').length) {
                $('#wrapper').addClass('m-pdp-with-sticky-search');
            }
        };
    }
}

module.exports = stickySearch;
