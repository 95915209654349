'use strict';

module.exports = {
	init: function () {
	    $('#liveagent_button_online').on("click", function(e){
	    	e.preventDefault();
	    	liveagent.startChat(SitePreferences.SF_BUTTON_ID); //button id
	    });

		liveagent.init(SitePreferences.SF_INIT_CHAT_URL, SitePreferences.SF_DEPLOYMENT_ID, SitePreferences.SF_ORG_ID); //url, deployment, org
	
		if (!window._laq)
		{ window._laq = []; }

		window._laq.push(function() {
		    liveagent.showWhenOnline(SitePreferences.SF_BUTTON_ID, document.getElementById('liveagent_button_online'));
		    liveagent.showWhenOffline(SitePreferences.SF_BUTTON_ID, document.getElementById('liveagent_button_offline'));
		});
	}
}
