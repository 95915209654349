'use strict';

var compareWidget = require('../compare-widget'),
    productTile = require('../product-tile'),
    progress = require('../progress'),
    addToCart = require('./product/addToCart'),
    variant = require('./product/variant'),
    util = require('../util');

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');


        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass('infinite-scroll-loading');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            $('div.search-result-content').append(html);
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage['scroll-cache_' + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                // update UI
                fillEndlessScrollChunk(response);
                productTile.init();
            }
        });
    }
}

function filterMultiSelection() {

    $('#secondary.hideContent').on('click', '.refinement-link, [id*="swatch-"],[id*="simple-attr-"]', function (e) {
        //e.stopImmediatePropagation();
        try {
            let selectedItem = $(this);
            let urlParam = selectedItem.data("param");
            if(!urlParam){
                return true
            }
            let isSelected = selectedItem.closest('li').hasClass('selected');
            
            let urlFilters = $('#secondary').data('urlFilters');
            let urlObj = new URL(urlParam);
            let baseUrl = new URL(urlObj.href.replace(urlObj.search,''));            
            let tmpURL  = new URL(urlFilters ? urlFilters : baseUrl);
            
            let isGroup = false;
            let groupClass = '.ref-def-' + selectedItem.data('refinementid');
            let refDefGroup = {
                refinementid: selectedItem.data('refinementid'),
                refinementvalue: selectedItem.data('refinementvalue'),
                prefNo:selectedItem.data('loopstateindex') ?  Number(selectedItem.data('loopstateindex')) : 1,
                selectedValues:[]
            }
            if (groupClass) {
                isGroup = true;
                $(groupClass).each(function() {
                    let rdItem = $(this);
                    console.log(rdItem);
                    if (rdItem.closest('li').hasClass('selected')) {
                        if(selectedItem.data('refinementid') !== rdItem.data('refinementvalue')){
                            refDefGroup.selectedValues.push(rdItem.data('refinementvalue'));
                        }
                    }
                });
            }
            
            if (urlObj) {
                if (isSelected) { // isSelected  then remove from searchParams
                    // Filter params to delete
                    urlObj.searchParams.forEach((valueRA, keyRA) => {
                        let value = valueRA;
                        let key = keyRA;
                        if(refDefGroup.refinementid === value){
                            if(keyRA.indexOf('prefn') != -1){
                                key = 'prefn' + refDefGroup.prefNo;
                            }
                        }
                        if(keyRA.indexOf('prefv') != -1){
                            value = refDefGroup.refinementvalue;
                            key = 'prefv' + refDefGroup.prefNo;
                            if(refDefGroup.refinementid !== value && refDefGroup.selectedValues.includes(value)){
                                refDefGroup.selectedValues= refDefGroup.selectedValues.filter(item => item !== value);
                            }
                            
                            value = refDefGroup.selectedValues.join('|');
                        }
                        if(!baseUrl.searchParams.has(key,value) || !value){
                            if(!value){
                                tmpURL.searchParams.delete(key);    
                            } else {
                                tmpURL.searchParams.set(key,value);
                            }
                        }
                    });
                    // UX unselect
                    selectedItem.closest('li').removeClass('selected');
                    selectedItem.find('i').attr('class','fa fa-square-o fa-lg');
                    
                } else { // append to querystring
                    urlObj.searchParams.forEach((valueRA, keyRA) => {
                        let value = valueRA;
                        let key = keyRA;
                        if(refDefGroup.refinementid === value){
                            if(keyRA.indexOf('prefn') != -1){
                                key = 'prefn' + refDefGroup.prefNo;
                            }
                        }
                        if(keyRA.indexOf('prefv') != -1){
                            value = refDefGroup.refinementvalue;
                            if(refDefGroup.refinementid !== value && !refDefGroup.selectedValues.includes(value)){
                                key = 'prefv' + refDefGroup.prefNo;
                                refDefGroup.selectedValues.push(value);
                            }
                            value = refDefGroup.selectedValues.join('|');
                        }
                        tmpURL.searchParams.set(key, value);
                    });
                    // UX select
                    selectedItem.closest('li').addClass('selected');
                    selectedItem.find('i').attr('class','fa fa-check-square-o fa-lg');
                    
                }
                $('#secondary').data('urlFilters', tmpURL.href);
            }
        } catch (ferror) {
            console.error('Debugging: ', ferror);
        }
        
        return false;
    });
}


function closeThickInit(){
    var $main = $('#main');
    //$('#secondary').append("<div id='closethick' class='applyfilters'></div>");
    $('.applyfilters').on('click',function () {
        let urlFilters = $('#secondary').data('urlFilters');
        if(urlFilters) {
            let tmpURL  = new URL(urlFilters);
            updateProductListing(tmpURL.href);
        }
        $("#secondary").removeClass('hideContent');
        //$(".c-overlay").hide();
    });
    // handle toggle refinement section
    /*$main.on('keydown', function (event) {
        if (event.key == "Escape") {
            $("#secondary").removeClass('hideContent');
            $(".c-overlay").hide();
        }
    });*/
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url) {
    if (!url || url === window.location.href) {
        return;
    }
    progress.show($('.search-result-content'));
    //util.scrollBrowser(0);// send to the bottom
    $('#main').load(util.appendParamToURL(url, 'format', 'ajax'), function () {
        compareWidget.init();
        productTile.init();
        progress.hide();
        closeThickInit();
        history.pushState(undefined, '', url);
        var $tiles = $('.tiles-container .product-tile');
        if ($tiles.length > 0) {
            $tiles.syncHeight().each(function (idx) {
                $(this).data('idx', idx);
            });
        }
    });
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $('#main');
    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });

    });
    $( window ).load(function() {
    	setTimeout(function(){ 
		    $('.product-listing.nohit .slick').slick({
		        infinite: false,
		        slidesToShow: 3,
		        slidesToScroll: 1,
		        dots: false
		    });
    	}, 2000);
        var $tiles = $('.tiles-container .product-tile');
        if ($tiles.length > 0) {
            $tiles.syncHeight().each(function (idx) {
                $(this).data('idx', idx);
            });
        }
    });
    

    // handle toggle refinement blocks
    $main.on('click', '.refinement h3', function () {
        $(this).toggleClass('expanded')
        .siblings('ul').toggle();
    });

    // handle events for updating grid
    $main.on('click', '.refinements a, .pagination a, .breadcrumb-refinement-value a', function (e) {
        // don't intercept for category and folder refinements, as well as unselectable
        if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable')) {
            return;
        }
        e.preventDefault();
        updateProductListing(this.href);
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not(".quickviewbutton")', function () {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        var idx = tile.data('idx') ? + tile.data('idx') : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on('change', '.sort-by select', function (e) {
        e.preventDefault();
        updateProductListing($(this).find('option:selected').val());
    })
    .on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl);
        }
    });
    /** new enhancements */
    // handle toggle refinement section
    $main.on('click', '.filtersbtn', function () {
        let $secondary =  $("#secondary");
        let $primary = $("#primary");
        let display = !$secondary.hasClass('hideContent');
        if(display){
            $secondary.addClass('hideContent');    
            $primary.addClass('full-width');
            $('.filtersbtn .filterbuttonhide').hide();
            $('.filtersbtn .filterbutton').show();
            filterMultiSelection();
        } else {
            $secondary.removeClass('hideContent');
            $primary.removeClass('full-width'); 
            $('.filtersbtn .filterbuttonhide').show();
            $('.filtersbtn .filterbutton').hide();
        }
        
        console.log("display",display);
        
    });

    closeThickInit();
    
    // $(".totalproductnumber").detach().appendTo('#primary .slot-grid-header .html-slot-container');
    
    $main.on('click','.buttonloadmore', function() {
        let loadPagesUrls = $(this).data("loadpages");
        if (loadPagesUrls && loadPagesUrls.length > 0) { // Check if loadPagesUrls is defined
                updateProductListing(loadPagesUrls[0]);
        } else {
                $('.buttonloadmore').hide();
                //TODO: Show message with no more results. 
        }
    });
    addToCart();
    variant();
}

exports.init = function () {
    compareWidget.init();
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $(window).on('scroll', infiniteScroll);
    }
    productTile.init();
    initializeEvents();
};
