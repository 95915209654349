'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    dialog = require('../../dialog'),
    _ = require('lodash');
const { title } = require('../../page');

var shippingMethods;
/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    $('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
    });
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});
    ajax.getJson({
        url: url,
        callback: function (data) {
            updateSummary();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

            // TODO the for loop below isn't doing anything?
            // if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
            //     var len = data.shippingPriceAdjustments.length;
            //     for (var i=0; i < len; i++) {
            //         var spa = data.shippingPriceAdjustments[i];
            //     }
            // }
        }
    });
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    var $shippingMethodList = $('#shipping-method-list');
    if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }
    var url = getShippingMethodURL(Urls.shippingMethodsJSON);

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }
            if (shippingMethods && shippingMethods.toString() === data.toString()) {
                // No need to update the UI.  The list has not changed.
                return true;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);

            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
            $shippingMethodList.load(smlUrl, function () {
                $shippingMethodList.fadeIn('fast');
                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.find('[name$="_shippingMethodID"]').click(function () {
                    selectShippingMethod($(this).val());
                });

                // update the summary
                updateSummary();
                progress.hide();
                tooltip.init();
                //if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                    $shippingMethodList.find('.input-radio:first').prop('checked', 'checked');
                }
            });
        }
    });
}

var loqateHelper = {
   
    options : {
        key: SitePreferences.LOQATE_KEY,
        manualEntryItem: true, 
        setCountryByIP: false
    },
    
    init : function() {
        
        var serviceAvailable = this.checkServiceAvailability();
        
        
        return;
        
        if (!serviceAvailable) {
            return;
        }
        var _this = this;
        $('[name*=_billingAddress_addressFields_address1]').on('focus', function () {
            _this.initOnBillingAddress();
        });
        
        $('[name*=_shippingAddress_addressFields_address1]').on('focus', function () {
            _this.initOnShippingAddress();
        });
        _this.initOnShippingAddress();
    },
    
    initBillingAddressEvent : function () {
        var serviceAvailable = this.checkServiceAvailability();
        
        if (!serviceAvailable) {
            return;
        }
        var _this = this;
        $('[name*=_billingAddress_addressFields_address1]').on('focus', function () {
            _this.initOnBillingAddress();
        });
    },
    
    initOnBillingAddress : function () {
        
        var $billingCounty = $('[name*=_billingAddress_addressFields_countries]');
        var serviceAvailable = this.checkServiceAvailability();
        if (!serviceAvailable || !$billingCounty.length) {
            return;
        }
        var stateElementSelector = '[name*=_billingAddress_addressFields_states]';
        var billingState = $('[name*=_billingAddress_addressFields_states]');
        
        var billingCountry = $billingCounty.val();
        var billingFields = [
              {element: "_billingAddress_addressFields_address1", field: "Line1" },
              {element: "_billingAddress_addressFields_address2", field: "Line2", mode: pca.fieldMode.POPULATE },
              {element: "_billingAddress_addressFields_city", field: "City", mode: pca.fieldMode.POPULATE },
              {element: "_billingAddress_addressFields_postal", field: "PostalCode", mode: pca.fieldMode.POPULATE}
        ];
        if (billingState.length) {
            billingFields.push({element: billingState.attr('name'), field: "ProvinceName", mode: pca.fieldMode.POPULATE });
        }
        
        var billingOptions = this.options;
        billingOptions.search = {countries: billingCountry.toUpperCase()};
        
        this.billingControl = new pca.Address(billingFields, billingOptions);
        
        this._addCustomEvents(
            this.billingControl,
            {
                'country' : billingCountry,
                'stateElementSelector' : stateElementSelector,
                'postalElementSelector' : "[name$='_billingAddress_addressFields_postal']",
                'billing' : true
            }
        );
    },
    
    initOnShippingAddress : function () {
        var $shippingCountry =  $("[name$='_shippingAddress_addressFields_country']");
        var serviceAvailable = this.checkServiceAvailability();
        if (!serviceAvailable) {
            return;
        }
        var stateElementSelector = "[name$='_shippingAddress_addressFields_states_state']";
        var shippingState = $(stateElementSelector);
        
        var shippingCountry = $shippingCountry.val();
        var shippingFields = [
              {element: "_shippingAddress_addressFields_address1", field: "Line1" },
              {element: "_shippingAddress_addressFields_address2", field: "Line2"/*, mode: pca.fieldMode.POPULATE */},
              {element: "_shippingAddress_addressFields_city", field: "City"/*, mode: pca.fieldMode.POPULATE */},
              {element: "_shippingAddress_addressFields_postal", field: "PostalCode"/*, mode: pca.fieldMode.POPULATE*/}
        ];
        
        if (shippingState.length) {
            shippingFields.push({element: "_shippingAddress_addressFields_states_state", field: "ProvinceCode"/*, mode: pca.fieldMode.POPULATE */});
        }

        var shippingOptions = this.options;
        shippingOptions.search = {countries: shippingCountry.toUpperCase()};
        this.shippingControl = new pca.Address(shippingFields, shippingOptions);
        
        this._addCustomEvents(
                this.shippingControl,
                {
                    'country' : shippingCountry,
                    'stateElementSelector' : stateElementSelector,
                    'postalElementSelector' : "[name$='_shippingAddress_addressFields_postal']",
                    'shipping' : true
                }
            );
        
    },
    
    initOnProfileAddress : function () {
        var $profileCountry =  $("[name*=_profile_address_countries]");
        var serviceAvailable = this.checkServiceAvailability();
        if (!serviceAvailable || !$profileCountry.length) {
            return;
        }
        var stateElementSelector = '[name*=_profile_address_states]';
        var $profileState = $(stateElementSelector);
        
        var profileCountry = $profileCountry.val();
        var profileFields = [
              {element: "_profile_address_address1", field: "Line1" },
              {element: "_profile_address_address2", field: "Line2", mode: pca.fieldMode.POPULATE },
              {element: "_profile_address_city", field: "City", mode: pca.fieldMode.POPULATE },
              {element: "_profile_address_postal", field: "PostalCode", mode: pca.fieldMode.POPULATE}
        ];
        
        if ($profileState.length) {
            profileFields.push({element: $profileState.attr('name'), field: "ProvinceName", mode: pca.fieldMode.POPULATE });
        }

        var profileOptions = this.options;
        profileOptions.search = {countries: profileCountry.toUpperCase()};
       
        this.profileControl = new pca.Address(profileFields, profileOptions);
        
        this._addCustomEvents(
                this.profileControl,
                {
                    'country' : profileCountry,
                    'stateElementSelector' : stateElementSelector,
                    'postalElementSelector' : "[name$='_profile_address_postal']",
                    'profile' : true
                }
            );
        
    },
    
    checkServiceAvailability : function () {
        if (typeof pca === 'undefined' || !pca) {
            return false;
        }
        return true;
    },
   
    _addCustomEvents : function (control, config) {
       if (!control) {
           return;
       }
       
       control.listen("populate", function(address) {
           // populate GB county with AdminAreaName data if it is not populated yet 
           if (config.country.toLowerCase() === 'gb') {
               var $province = $(config.stateElementSelector);
               var province = address.Province;
               var adminAreaName = address.AdminAreaName;

               var proviceNameOptionVal = getOptionWithTextValue(config.stateElementSelector, province);
               var adminAreaNameOptionVal = getOptionWithTextValue(config.stateElementSelector, adminAreaName);
               if (proviceNameOptionVal) {
                   $province.val(proviceNameOptionVal);
               } else if (adminAreaNameOptionVal) {
                   $province.val(adminAreaNameOptionVal);
               } else if ($(config.stateElementSelector + ' option[value="' +province+ '"]').length) {
                   $province.val(province);
               }
           }
           // auto populate Postal code for the Hong Kong 
           if (config.country.toLowerCase() === 'hk') {
               var $postal = $(config.postalElementSelector);
               if ($postal.length) {
                   $postal.val(SitePreferences.HONG_KONG_DEFAULT_POSTAL);
               }
           }

           if (config.shipping) {
               $("[name$='_shippingAddress_addressFields_address1']").trigger('shipping-address-populated');
           }
           
           if (config.billing) {
               $("[name$='_billingAddress_addressFields_address1']").trigger('billing-address-populated');
           }
           
           if (config.profile) {
               $("[name$='_profile_address_address1']").trigger('profile-address-populated');
           }
       });
       
       function getOptionWithTextValue(selectSelector, text) {
           var value = '';
           $(selectSelector + ' option').each(function() {    
               if($(this).text().toString() === text) {
                   value = $(this).val();
                   return false;
               }
           });
           return value;
       }
    }
}

/**
 * @function
 * @description filter emoji symbols
 * @param String
 * @return String
 */
function filterEmoji (str) {
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return str.replace(regex, '');
}

function populateAdrr() {

    var $shippinForm = $('form.checkout-shipping');
    var url = $shippinForm.data('avs-url');
    
    if(!url){
        return;
    }

    $.ajax({
        type: "POST",
        url: url,
        data: $shippinForm.serialize(),
        success: function (response) {
            if (response) {
                dialog.open({
                    html: response,
                    options: {
                        modal:true,
                        width: 570,
                        open: function () {
                            $('.ui-dialog-titlebar').hide();
                            $(document).on('click','#recom_address, #btn_recomended', function (e) {
                                e.preventDefault();
                                var addrData = JSON.parse($('input[type=radio][id=recom_address]').val());
                                $("input[name$='_singleshipping_shippingAddress_addressFields_city']").val(addrData.Locality);
                                $("input[name$='_singleshipping_shippingAddress_addressFields_postal']").val(addrData.PostalCodePrimary);
                                $("select[name$='_singleshipping_shippingAddress_addressFields_states_state']").val(addrData.AdministrativeArea);
                                $("input[name$='_shippingAddress_addressFields_address1']").val(addrData.DeliveryAddress1);
                                $("input[name$='_shippingAddress_addressFields_address2']").val(addrData.Address2);
                                dialog.close();
                                $('#dwfrm_singleshipping_shippingAddress').data('avs-confirmed',1);
                                $("button[name='dwfrm_singleshipping_shippingAddress_save']").first().trigger('click');
                                
                            });

                            $(document).on('click','#btn_entered, #entered_address', function (e) {
                                e.preventDefault();
                                dialog.close();
                                $('#dwfrm_singleshipping_shippingAddress').data('avs-confirmed',1);
                                $("button[name='dwfrm_singleshipping_shippingAddress_save']").first().trigger('click');
                            });
                        }
        
                    }
                });
            }
        },
        error: function(error){
            console.log('Error AVS request'); // never expose public error  messages
        }
    });

    
}

exports.init = function () {
    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector:'[id$="singleshipping_shippingAddress"]'
    });
    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);

    $('.address').on('change',
        'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_zip"]',
        updateShippingMethodList
    );

    $('#dwfrm_singleshipping_shippingAddress').on('submit', function(e){
        var avsUrl = $('#dwfrm_singleshipping_shippingAddress').data('avs-url');
        if(avsUrl){
            let confirmed = $('#dwfrm_singleshipping_shippingAddress').data('avs-confirmed');
            if(!confirmed){
                e.preventDefault();
                populateAdrr();
            }
        }
    });

    

    giftMessageBox();
    updateShippingMethodList();
};

exports.updateShippingMethodList = updateShippingMethodList;
