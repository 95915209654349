'use strict';

var account = require('./account'),
    bonusProductsView = require('../bonus-products-view'),
    quickview = require('../quickview'),
    cartStoreInventory = require('../storeinventory/cart');

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    $('#cart-table').on('click', '.item-edit-details a', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
    })
    .on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    });
    
    $('.update-item, .cart-update-msg').hide();
    
    $('form input[name$="_quantity"]').change(function() {
        $(this).closest('.cart-row').find('.item-quantity-details .item-user-actions .update-item').show();
        $('.cart-update-msg').show();
    });

    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) {
        		return false;
        	} else if (e.which === 13) {
        		e.preventDefault();
        		$('form button[name$="_cart_addCoupon"]').trigger('click');
        	}
    });
    
    //trigger update cart button
    $('.updatecart-trigger, .update-item').on('click',function(e) {
    	e.preventDefault();
    	$('#update-cart').trigger( "click" );
    });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });
    $( window ).load(function() {
    	setTimeout(function(){
		    $('.slick').each(function() {
		        var numberOfSlides = $(this).children().length;
		        if (numberOfSlides > 1) {
		            var slidesToShowDesktop = 3;
		            if (numberOfSlides < slidesToShowDesktop) {
		                slidesToShowDesktop = numberOfSlides;
		            }
		            $(this).slick({
		                infinite: false,
		                slidesToShow: slidesToShowDesktop,
		                slidesToScroll: 1,
		                responsive: [
		                {
		                  breakpoint: 959,
		                  settings: {
		                    slidesToShow: 2
		                  }
		                },
		                {
		                  breakpoint: 767,
		                  settings: {
		                    arrows:false,
		                    slidesToShow: 1,
		                    dots: true
		                  }
		                }
		                ]
		            }); 
		        }
		    });
    	}, 2000);
    });
    
    // Prevents the form from submitting if nothing has been entered
    $('button[name$="_addDonation"]').on('click', function (e) {
    		if (!$('input[name$="_donationAmount"]').val()) {
    			e.preventDefault();
    		}
    });
}

exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
};
