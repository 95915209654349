'use strict';

var changeQuantity = function () {
    var quantityCtas = ['button.js-quantity-decrease-button', 'button.js-quantity-increase-button'];
    $(quantityCtas).each(function(idx, attr) {
        $pdpMain.on('click', attr, function() {
            var $quantityInput = $(attr).siblings('.js-product-quantity-input');
            var quantityInputVal = parseInt($quantityInput.val());
            var modificatedQuantity;

            if ($(attr).hasClass('js-quantity-decrease-button')) {
                modificatedQuantity = quantityInputVal > 1 ? quantityInputVal - 1 : 1;
            } else if ($(attr).hasClass('js-quantity-increase-button')) {
                modificatedQuantity = quantityInputVal + 1;
            }

            return $quantityInput.val(modificatedQuantity);
        });
    });
}

module.exports = function () {
    $('.js-quantity-button').on('click', changeQuantity);
}
