'use strict';

var dialog = require('../../dialog');
var util = require('../../util');

var maxHeight = function() {
    var isMob = util.isMobile() || matchMedia('(max-width: 550px)').matches;
    var height;
    var windowHeight = $(window).height();
    if (isMob) {
        height = windowHeight * 0.7;
    } else {
        height = windowHeight * 0.9;
    }
    return height;
}

var sizeChart = function () {
    var $content = $('#size-chart-content');

    $('body').on('click', '.js-size-chart-btn', function() {
        dialog.open({
            html: $content.html(),
            options: {
                dialogClass: "size-chart-modal",
                maxHeight: maxHeight(),
                open: function(event, ui) {
                   
                    $(event.target).scrollTop(0);
                }
            }
        })
    });
}

module.exports = sizeChart;
