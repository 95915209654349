'use strict';
var carouselConfig = require('../../configs/carousel');

/**
 * Carousel initialization function
 * @param {Object} $carousel - jQuery object
 * @param {Object} config - carousel settings
 */
function initCarousel($carousel, config) {
    $carousel.each(function(ind, item) {
        // var config = carouselConfig.einsteinRecommendationCarousel;
        var numberOfSlides = $(item).children().length;
        // numberOfSlides > config.slidesToShow && 
        if (!$(item).hasClass('slick-initialized')) {
            $(item).slick(config).data('carousel', config);
        }
    });
}

/**
 * Init Product Recommendation Carousel
 */
function initProductRecommendationCarousel() {
    var $carousel = $('#carousel-recommendations .slick');
    var config = carouselConfig.recommendationCarousel;
    initCarousel($carousel, config)
}

/**
 * Init Einstein Recommendation Carousel
 */
function initEinsteinRecommendationCarousel() {
    var $carousel = $('.einstein-recommendation .slick');
    var config = carouselConfig.einsteinRecommendationCarousel;
    
    $(document).on('einstein:loaded', function () {
        $carousel = $('.einstein-recommendation .slick');
        initCarousel($carousel, config);
    });

    if ($carousel.length) {
        initCarousel($carousel, config);
    }
}

/**
 * @description Creates product recommendation carousels
 **/
module.exports = function () {
    initProductRecommendationCarousel()
    initEinsteinRecommendationCarousel();
};
