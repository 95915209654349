'use strict';

const { values } = require("lodash");

function getCurrentData(){
	if(sjdata.page == 'shipping') {
		$(document).trigger('data:checkout', [sjdata]);
	} else if(sjdata.page == 'orderconfirmation' || sjdata.page == 'confirmation') {
		$(document).trigger('data:purchase', [sjdata]);
	} else if(sjdata.page == 'product') {
		$(document).trigger('data:pdp', [sjdata]);
	} else if(sjdata.page == 'summary') {
		$(document).trigger('data:payment', [sjdata]);
	}

}

module.exports = {
	init: function () {
	    $(document).on("data:addtocart", function(e, data){
			ttq.track('AddToCart', {
				content_type: data.content_type,
				quantity: data.qty,
	    		content_name: data.content_name,
				content_id: data.content_ids,
				currency: 'USD',
				value: data.cartTotal
			});
	    });

		$(document).on("data:checkout", function(e, data){
	    	ttq.track('InitiateCheckout', {
	    		value: data.cartTotal,
	    		currency: data.currency,
	    		quantity : data.qty,
	    		contents : [data.pids]
	        });
	    });

	    $(document).on("data:purchase", function(e, data){
	    	ttq.track('Purchase', {
	    		contents: [data.pids],
	    		currency: data.currency,
	    		value: data.cartTotal,
				quantity : data.qty
	        });
	    });
		
	    $(document).on("data:pdp", function(e, data){
	    	ttq.track('ViewContent', {
				quantity : data.qty,
				currency: data.currency,
				value: data.value,
				contents : data.pids,
	    		content_type: data.content_type,
	    		content_name: data.content_name
	        });
	    });

	    $(document).on("data:payment", function(e, data){
	    	ttq.track('AddPaymentInfo',  {
	    		contents: [data.pids],
	    		currency: data.currency,
	    		value: data.cartTotal
	        });
	    });

		getCurrentData();
	}
};