'use strict';

var stickyComponent = {
    init: function (params) {
        this.isInit = false;

        if(this.isExist(params)) {
            var $triggerBlock = $(params.trigger);
            var triggerBottomPoint = $triggerBlock.offset().top + $triggerBlock.height();
            
            var stickyContext = this;
            $(window).on('scroll load', function () {
                var initEvent;
                clearTimeout(initEvent);
            
                initEvent = setTimeout(function() {
                    if ($(window).scrollTop() > triggerBottomPoint && !stickyContext.isInit) {
                        stickyContext.enable();
                    } else if ($(window).scrollTop() < triggerBottomPoint && stickyContext.isInit) {
                        stickyContext.disable();
                    }
                }, 300);
            });
        }
    },
    isExist: function (params) {
        if (params && params.selector && $(params.selector).length) {
            return this.$stickyBlock = $(params.selector);
        } else if (this.$stickyBlock) {
            return this.$stickyBlock;
        } else {
            return false;
        }
    },
    enable: function(params) {
        if(this.isExist(params)) {
            this.$stickyBlock.addClass('m-sticky-enable').removeClass('m-sticky-disable');
            this.isInit = true;
        }
    },
    disable: function (params) {
        if(this.isExist(params)) {
            this.$stickyBlock.removeClass('m-sticky-enable').addClass('m-sticky-disable');
            this.isInit = false;
        }
    }
};

module.exports = stickyComponent;
