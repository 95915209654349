'use strict';
var carouselConfig = require('../configs/carousel');
var util = require('../util');

exports.init = function () {
    $('#homepage-slider .slick').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        arrows: false
    });

    $('#vertical-carousel .slick').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
    });
    
    $( window ).load(function() {
    	setTimeout(function(){ 
		    $('.product-listing.homepage .slick').slick({
		        infinite: false,
		        slidesToShow: 3,
		        slidesToScroll: 1,
		        dots: false
		    });
            var isMob = util.isMobile() || matchMedia('(max-width: 550px)').matches;
            $('.carousel-hp .product-listing .slick').slick({
		        infinite: false,
		        slidesToShow: isMob ? 2 : 4,
		        slidesToScroll: 1,
		        dots: false
		    });
    	}, 2000);
    });
    
    
};
