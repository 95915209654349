'use strict';

var address = require('./address'),
    billing = require('./billing'),
    multiship = require('./multiship'),
    shipping = require('./shipping');

function initRoundUpDonations() {
	$('input[name$="isRoundUpDonation"').on('click', function () {
		var previousSetting = $(this).data('checked') ? true : false,
			options = {
	            url: Urls.SetCheckoutRoundUpDonations,
	            data: 'enableRoundUpDonation=' + !previousSetting,
	            type: 'POST'
	        };
		
		// Un-check the radio button
		if (previousSetting == true) {
			$(this).prop('checked', false);
			$(this).data('checked', false);
		} else {
			$(this).data('checked', true);
		}
		
		// The new setting is always the opposite of the previous
		if (!previousSetting) {
			updateRoundUpTotalDisplay(true);
		} else {
			updateRoundUpTotalDisplay(false);
		}
	});
	
	$('<span class="slider round"></span>').insertAfter("#dwfrm_billing_donations_isRoundUpDonation");

}

function updateRoundUpTotalDisplay(enabled) {
	var $orderTotalElement = $(".order-total .order-value"),
		$donationTotalElement = $(".donations-total .donations-value"),
		originalOrderTotal = $orderTotalElement.data('originalTotal'),
		originalDonationTotal = $donationTotalElement.data('originalTotal'),
		newDonationTotal;
	
	// If it's null, it hasn't been set before, so the original totals are still displaying
	originalOrderTotal = getOrSetStoredValue(originalOrderTotal, $orderTotalElement);
	originalDonationTotal = getOrSetStoredValue(originalDonationTotal, $donationTotalElement);
	
	if (enabled) {
		// Update the values to factor in round-up donation
		newDonationTotal = originalDonationTotal + getRoundUpAmount(originalOrderTotal);
		if (Resources.CURRENCY_SYMBOL_PLACEMENT == 'before') {
			$donationTotalElement.html(Resources.CURRENCY_SYMBOL + newDonationTotal.toFixed(2));
			$orderTotalElement.html(Resources.CURRENCY_SYMBOL + Math.ceil(originalOrderTotal).toFixed(2));	
		} else {
			$donationTotalElement.html(newDonationTotal.toFixed(2) + Resources.CURRENCY_SYMBOL);
			$orderTotalElement.html(Math.ceil(originalOrderTotal).toFixed(2) + Resources.CURRENCY_SYMBOL);
		}
	} else {
		// Grab the original total from the js data element
		if (Resources.CURRENCY_SYMBOL_PLACEMENT == 'before') {
			$donationTotalElement.html(Resources.CURRENCY_SYMBOL + $donationTotalElement.data('originalTotal').toFixed(2));
			$orderTotalElement.html(Resources.CURRENCY_SYMBOL + $orderTotalElement.data('originalTotal').toFixed(2));
		} else {
			$donationTotalElement.html($donationTotalElement.data('originalTotal').toFixed(2) + Resources.CURRENCY_SYMBOL);
			$orderTotalElement.html($orderTotalElement.data('originalTotal').toFixed(2) + Resources.CURRENCY_SYMBOL);
		}
	}
}

function getOrSetStoredValue(data, domElement) {
	if (data == null) {
		data = Number(domElement.html().replace(/[^0-9\.-]+/g,""));
		domElement.data('originalTotal', data);
	}
	
	return data;
}

function getRoundUpAmount(OrderTotal) {
	console.log('Calculating round-up amount');
	return Math.ceil(OrderTotal) - OrderTotal;
}

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    if ($('.checkout-shipping').length > 0) {
        shipping.init();
    } else if ($('.checkout-multi-shipping').length > 0) {
        multiship.init();
    } else {
        billing.init();
    }

    //if on the order review page and there are products that are not available disable the submit order button
    if ($('.order-summary-footer').length > 0) {
        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        }
    }
    
    initRoundUpDonations();
    
    $('.js-submit-order').off('click.submit-order-ns').on('click.submit-order-ns', function(e) {
        e.preventDefault()
        $(this).prop('disabled', true);
        $(this).closest('form.submit-order').submit();
    });
    
    $('.js-submit-order').dblclick(false);
};

