'use strict';

function getCurrentData(){
	if(sjdata.page == 'shipping') {
		$(document).trigger('data:checkout', [sjdata]);
	} else if(sjdata.page == 'orderconfirmation' || sjdata.page == 'confirmation') {
		$(document).trigger('data:purchase', [sjdata]);
	} else if(sjdata.page == 'product') {
		$(document).trigger('data:pdp', [sjdata]);
	} else if(sjdata.page == 'summary') {
		$(document).trigger('data:payment', [sjdata]);
	}
	
}

function customPararmeter(data) {
    var array = data.pids;
    var j = 0;
    var contentIDS= [];
    for (var i = 0; i < array.length; i++){
    var testID = array[i];
    if(testID.hasOwnProperty('id')){
        contentIDS[j] = testID['id'];
        j++;
    }
    }
   return contentIDS;
}

module.exports = {
	init: function () {
	    $(document).on("data:addtocart", function(e, data){
	    	fbq('track', 'AddToCart', {
	            content_ids: [data.content_ids],
	            content_type: 'product' 
	        });
	    });
	    $(document).on("data:checkout", function(e, data){
	    	fbq('track', 'InitiateCheckout', {
	    		value: data.cartTotal,
	    		currency: data.currency,
	    		num_items : data.qty,
	    		contents : data.pids
	        });
	    });
	    $(document).on("data:purchase", function(e, data){
	    	fbq('track', 'Purchase', {
	    		value: data.cartTotal,
	    		currency: data.currency,
	    		num_items : data.qty,
	    		content_type: 'product',
	    		contents : data.pids,
				content_ids : customPararmeter(data)
	        });
	    });
	    $(document).on("data:pdp", function(e, data){
	    	fbq('track', 'ViewContent', {
	    		content_ids: data.pids,
	    		content_type: data.content_type,
	    		content_name: data.content_name
	        });
	    });
	    $(document).on("data:payment", function(e, data){
	    	fbq('track', 'AddPaymentInfo', {
	    		contents: data.pids,
	    		currency: data.currency,
	    		value: data.cartTotal
	        });
	    });
	    
		getCurrentData();
	}
};